<template>
  <div id="bd">
    <div id="ban-in">
      <div class="ban-bg"></div>
      <div class="banner-box wp">
        <h3>联系我们</h3>
        <h5>Contact Us</h5>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="row4 fix contactMain">
      <div class="wp">
        <div class="tit-i">
          <h3>联系我们</h3>
          <h5><span>contact</span> ronghe</h5>
        </div>
        <div class="contact-l">
          <ul class="ul-contact">
            <li class="li1">
              江西省南昌市红谷滩区南昌市九龙大道1388号VR产业基地三号楼6楼
            </li>
            <li class="li2">
              <span>0791-88553836 (主机)</span>
              <span>18179115029 (客服)</span>
            </li>
            <li class="li3">
              <span>3243452665@qq.com</span>
            </li>
          </ul>
          <div class="wxewm">
            <p>
              <img
                width="200px"
                height="200px"
                src="../../assets/front/headernew/ewm.jpg"
              />
              <span>扫一扫关注企业公众号</span>
            </p>
            <p>
              <img
                width="200px"
                height="200px"
                src="../../assets/front/headernew/yqym.png"
              />
              <span>扫一扫查看企业信息</span>
            </p>
          </div>
        </div>
        <div class="contact-r">
          <div id="map" class="mapbox"></div>
        </div>
      </div>
    </div>

    <!-- 加入我们 -->
    <div class="joinus">
      <div class="tit-i">
        <h3>加入我们</h3>
        <h5>
          JOIN
          <span>US</span>
        </h5>
      </div>
      <div class="wp">
        <ul class="join-left">
          <li>
            <img src="../../assets/front/contact/joinicon1.png" alt="" />
            <p>五险一金，保障员工合法权益</p>
          </li>
          <li>
            <img src="../../assets/front/contact/joinicon2.png" alt="" />
            <p>法定节假日，工作也要劳逸结合</p>
          </li>
          <li>
            <img src="../../assets/front/contact/joinicon3.png" alt="" />
            <p>定期体检，关爱员工身体健康</p>
          </li>
          <li>
            <img src="../../assets/front/contact/joinicon4.png" alt="" />
            <p>行业大佬带领定期学习分享充电</p>
          </li>
          <li>
            <img src="../../assets/front/contact/joinicon5.png" alt="" />
            <p>生日津贴、生育津贴、结婚津贴，给员工亲人般的慰问关怀</p>
          </li>
          <li>
            <img src="../../assets/front/contact/joinicon6.png" alt="" />
            <p>各类团建活动，吃喝玩乐一样不落下</p>
          </li>
        </ul>
        <div class="join-right">
          <img src="../../assets/front/contact/qrcode.png" alt="" />
          <p>微信扫码查看招聘职位</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Partners from './components/partners.vue';
// import ProductSystem from '../product/components/ProductSystem.vue';
import location from '../../assets/icon/location.png';
import videoBg from '../../assets/bg/videoBg.png';
// import orgBg from '../../assets/bg/orgWhite.png';
import orgBg from '../../assets/bg/org.png';
import { ImagePreview } from 'vant';

import zs1 from '../../assets/front/headernew/zs1.png';
import zs2 from '../../assets/front/headernew/zs2.png';
import as1 from '../../assets/front/headernew/as1.png';
import as2 from '../../assets/front/headernew/as2.png';
import ys1 from '../../assets/front/headernew/ys1.png';
export default {
  name: 'home-wrapper',
  // components: { Partners, ProductSystem },
  data() {
    return {
      show: false,
      popShow: false,
      popList: [],
      popIndex: 0,
      pooArray: [
        [
          {
            title: '线上科技——井冈山产业招商地图',
            content:
              '井开区产业招商地图针对井开区主导产业，每个产业链节点分类不低于10个，平均每条产业链可获取目标企业数据不低于10000家。提供企业投资意向评估智能算法并按照相应算法动态给企业评估，自动绘制产业招商地图，每年提供精准更新招商项目库数量不低于20个。',
          },
          {
            title: '线下服务——助力东乡区人民政府招商引资',
            content:
              '融合科技为客户提供项目落地资金解决方案。目前融合科技助力东乡区招入三家企业：锻造轮毂、智慧教育、通航航空器，总投超过120亿，预计5年后年创税收超过10亿，第一年税收不低于1亿。',
          },
        ],
        [
          {
            title: '工业管理智慧云平台——为主管部门创新工业管理机制助力',
            content:
              '江西省工业智慧云平台建有全省一体化横向互联、纵向互通的智慧园区综合平台和省智慧工业大数据中心，是全国数据体量最大、服务体系最全、接入园区最多的省级智慧工业平台。',
          },
          {
            title: '企业服务平台——为工业企业转型升级服务模式赋能',
            content:
              '赣企服平台助力定南工业园区招工1500余人，促成华建英才人力资源公司与彩智电子科技有限公司长期合作招工协议。',
          },
        ],
        [
          {
            title: '亩均效益平台——优化产业区域布局',
            content:
              '亩均效益平台为工业高质量发展和工业考核提供数据支撑，为差别化资源配置和政策支撑提供方向，淘汰落后扶优扶强，打造优质可持续化营商环境。',
          },
        ],
      ],
      poplistArr: [
        [
          {
            imgSrc: zs1,
            title: '线上科技——井冈山产业招商地图',
            txt: '井开区产业招商地图针对井开区主导产业，每个产业链节点分类不低于10个，平均每条产业链可获取目标企业数据不低于10000家。提供企业投资意向评估智能算法并按照相应算法动态给企业评估，自动绘制产业招商地图，每年提供精准更新招商项目库数量不低于20个。',
          },
          {
            imgSrc: zs2,
            title: '线下服务——助力东乡区人民政府招商引资',
            txt: '融合科技为客户提供项目落地资金解决方案。目前融合科技助力东乡区招入三家企业：锻造轮毂、智慧教育、通航航空器，总投超过120亿，预计5年后年创税收超过10亿，第一年税收不低于1亿。',
          },
        ],
        [
          {
            imgSrc: as1,
            title: '工业管理智慧云平台——为主管部门创新工业管理机制助力',
            txt: '江西省工业智慧云平台建有全省一体化横向互联、纵向互通的智慧园区综合平台和省智慧工业大数据中心，是全国数据体量最大、服务体系最全、接入园区最多的省级智慧工业平台。',
          },
          {
            imgSrc: as2,
            title: '企业服务平台——为工业企业转型升级服务模式赋能',
            txt: '赣企服平台助力定南工业园区招工1500余人，促成华建英才人力资源公司与彩智电子科技有限公司长期合作招工协议。',
          },
        ],
        [
          {
            imgSrc: ys1,
            title: '亩均效益平台——优化产业区域布局',
            txt: '亩均效益平台为工业高质量发展和工业考核提供数据支撑，为差别化资源配置和政策支撑提供方向，淘汰落后扶优扶强，打造优质可持续化营商环境。',
          },
        ],
      ],
      videoBg,
      linkMessages: [
        '电话：0791-88553836',
        '地址：江西省南昌市丰和中大道912号地铁大厦13A',
        '客服：18179115029',
        '邮箱：3243452665@qq.com',
      ],
      serviceList: [
        {
          title: '优化产业布局',
          simpleTitle: '招',
          descripts: [
            '线上科技：提供产业链招商大数据服务',
            '线下金融：提供项目落地资金解决方案',
          ],
          bottomText: '线上线下双循环全面发力',
        },
        {
          title: '强化产业服务',
          simpleTitle: '安',
          descripts: [
            '为主管部门创新工业管理机制助力',
            '为工业企业转型升级服务模式赋能',
          ],
          bottomText: '企业经纪人提供量身服务',
        },
        {
          title: '深化产业培育',
          simpleTitle: '育',
          descripts: [
            '数据挖掘，招引培育服务型制造新业态',
            '躬身入局，投资参股两化融合新型企业',
          ],
          bottomText: '推动工业转型升级由服务到培育',
        },
      ],
    };
  },
  methods: {
    goPath(index) {
      if (index == 1) {
        window.open('https://www.jx968969.com/XOffice/login/province/yqyylist');
      } else if (index == 2) {
        window.open('https://www.jx968969.com/XOffice/login/province/gygllist');
      } else if (index == 3) {
        window.open('https://www.jx968969.com/XOffice/login/province/cyzdlist');
      } else if (index == 4) {
        window.open('https://www.jx968969.com/springBoot/site/360000');
      }
    },
    pauseVideo() {
      this.$refs.video.pause();
    },
    startViedo() {
      this.$refs.video.play();
    },
    preview() {
      ImagePreview([orgBg]);
    },
    businessPop(e) {
      this.popIndex = e;
      this.popShow = true;
      this.popList = this.poplistArr[e];
    },
  },
  mounted() {
    // 加载图片
    const AMap = window.AMap;
    const map = new AMap.Map('map', {
      resizeEnable: false,
      center: [115.798318, 28.607637],
      zooms: [14, 18],
      zoom: 15.8,
    });
    const marker = new AMap.Marker({
      position: new AMap.LngLat(115.798318, 28.607637),
      content: `<div class="company-location">
        <img src="${location}" alt="" width="40px" height="40px">
<!--        <div class="text">江西融合科技</div>-->
<!--        <div class="text">有限责任公司</div>-->
      </div>`,
      offset: new AMap.Pixel(-20, -40),
    });
    map.add(marker);
    var markerArr = {
      title: '江西融合科技有限责任公司',
      content: '江西省南昌市红谷滩区南昌市九龙大道1388号VR产业基地三号楼6楼',
      point: '115.798318, 28.607637',
      isOpen: 1,
      icon: { w: 23, h: 25, l: 46, t: 21, x: 9, lb: 12 },
    };
    var infoWindow = new AMap.InfoWindow({
      content:
        "<b class='iw_poi_title' title='" +
        markerArr.title +
        "'>" +
        markerArr.title +
        "</b><div class='iw_poi_content'>" +
        markerArr.content +
        '</div>',
      offset: new AMap.Pixel(10, -40),
    });

    infoWindow.open(map, new AMap.LngLat(115.798318, 28.607637));
    function showInfoM() {
      infoWindow.open(map, new AMap.LngLat(115.798318, 28.607637));
    }
    marker.on('click', showInfoM);
    document.addEventListener(
      'WeixinJSBridgeReady',
      function () {
        this.$refs.bgVideo.play();
      },
      false,
    );
  },
};
</script>
<style lang="scss" scoped>
#bd {
  // padding-top: 90px;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  background-size: 100% 100%;
  height: 750px;
  background: url(../../assets/front/product/contactBanner.png) center center
    no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.banner-box {
  height: 100%;
  padding-top: 250px;
  animation-name: fadeInUp;
  animation: fadeInUp 0.5s linear;
  h3 {
    font-size: 46px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  h5 {
    margin-top: 18px;
    font-size: 20px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 8px;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.contact-l {
  width: 38%;
  float: left;
}
.contact-r {
  width: 62%;
  float: right;
}
.ul-contact li {
  background: url(../../assets/front/i_icon2.png) no-repeat;
  padding-left: 60px;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 35px;
}
.ul-contact li.li1 {
  background-position: 0 0;
}
.ul-contact li.li2 {
  background-position: 0 -74px;
}
.ul-contact li.li3 {
  background-position: 0 -144px;
}
.contactMain {
  padding-bottom: 50px;
  height: 613px;
}
.contact-l .wxewm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
}
.contact-l .wxewm p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
.contact-l .wxewm img {
  width: 200px;
  height: 200px;
}
.contact-l .wxewm span {
  display: block;
  font: 16px/16px 'microsoft yahei';
  color: #333;
}
.contact-r {
  width: 58%;
  height: 350px;
}
.contact-r .mapbox {
  width: 100%;
  height: 100%;
}

.contact-form .inp {
  width: 49%;
  border: 1px solid #6d6d88;
  height: 33px;
  padding: 0 10px;
  margin-bottom: 15px;
}
.contact-form textarea {
  width: 100%;
  border: 1px solid #6d6d88;
  height: 98px;
  margin-bottom: 24px;
}
.contact-form .sub {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #63637f;
  border: 0;
  cursor: pointer;
  font-family: '΢���ź�', 'Microsoft Yahei';
  font-size: 16px;
}

.map {
  margin-top: 100px;
  position: relative;
}
.map em {
  background: url(../../assets/front/arr1.jpg) center top no-repeat;
  width: 100%;
  height: 15px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.map-s {
  background: url(../../assets/front/map_s.png) center top;
  height: 107px;
  text-align: center;
  overflow: hidden;
}
.map .btn {
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 132px;
  display: inline-block;
}
.map .btn-down {
  background: url(../../assets/front/map_down.png) no-repeat;
  width: 112px;
  height: 48px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 52px;
  margin-left: -50px;
  z-index: 99;
}
#map {
}
.map-pop {
  display: none;
  height: 540px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.map .map-bg1,
.map .map-bg2 {
  background: url(../../assets/front/ban_bg_top.png) no-repeat center 0;
  height: 54px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 66;
}
.map .map-bg2 {
  background-image: url(../../assets/front/map_bg2.png);
  height: 41px;
  bottom: 0px;
  top: auto;
}
.map-pop-big {
  height: 600px;
  bottom: auto;
  top: 0;
}
.map-big {
  height: 600px;
  margin-top: 0;
}

.joinus {
  height: 500px;
  background: url(../../assets/front/contact/joinusbg.png) center top repeat;
  background-size: 100% 100%;
}
.joinus .moduleTitle {
  padding-bottom: 20px;
}
.joinus .wp {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.joinus .join-left {
  width: 67%;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
.joinus .join-left li {
  width: 33%;
  min-height: 130px;
  margin-right: 0.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  margin-bottom: 30px;
  //padding: 0 30px;
}
.joinus .join-left li:nth-of-type(3n) {
  margin-right: 0;
}
.joinus .join-left li img {
  width: 80px;
  height: 80px;
}
.joinus .join-left li p {
  font: 14px/20px 'PingFang SC';
  color: #333;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.joinus .join-right {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
.joinus .join-right img {
  width: 240px;
  height: 240px;
  border: 1px dashed #f1f1f1;
}
.joinus .join-right p {
  font: 18px/40px 'PingFang SC';
  color: #333;
}
.moduleTitle {
  text-align: center;
  padding: 40px 0;
}
.moduleTitle h3,
.moduleTitleLeft h3 {
  font: 30px/40px 'PingFang SC';
  color: #333;
}
.moduleTitle h5,
.moduleTitleLeft h5 {
  font: 20px/35px 'PingFang SC';
  color: #999;
}
.moduleTitle h5 span,
.moduleTitleLeft h5 span {
  color: #1f73ff;
}
.moduleTitleLeft {
  padding: 40px 0;
}
</style>
